const CommonSection = (): JSX.Element => {
    return (
        <>
            <div className="position-absolute start-0 end-0 start-0 bottom-0 w-100 h-100">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="100%" height="100%" preserveAspectRatio="none"
                    viewBox="0 0 1920 1024">
                    <g mask="url(&quot;#SvgjsMask1046&quot;)" fill="none">
                        <rect width="1920" height="1024" x="0" y="0" fill="url(#SvgjsLinearGradient1047)"></rect>
                        <path d="M1920 0L1864.16 0L1920 132.5z" fill="rgba(255, 255, 255, .1)"></path>
                        <path d="M1864.16 0L1920 132.5L1920 298.4L1038.6100000000001 0z" fill="rgba(255, 255, 255, .075)"></path>
                        <path d="M1038.6100000000001 0L1920 298.4L1920 379.53999999999996L857.7000000000002 0z"
                            fill="rgba(255, 255, 255, .05)"></path>
                        <path d="M857.7 0L1920 379.53999999999996L1920 678.01L514.57 0z" fill="rgba(255, 255, 255, .025)"></path>
                        <path d="M0 1024L939.18 1024L0 780.91z" fill="rgba(0, 0, 0, .1)"></path>
                        <path d="M0 780.91L939.18 1024L1259.96 1024L0 585.71z" fill="rgba(0, 0, 0, .075)"></path>
                        <path d="M0 585.71L1259.96 1024L1426.79 1024L0 408.19000000000005z" fill="rgba(0, 0, 0, .05)"></path>
                        <path d="M0 408.19000000000005L1426.79 1024L1519.6599999999999 1024L0 404.09000000000003z"
                            fill="rgba(0, 0, 0, .025)"></path>
                    </g>
                    <defs>
                        <mask id="SvgjsMask1046">
                            <rect width="1920" height="1024" fill="#ffffff"></rect>
                        </mask>
                        <linearGradient x1="11.67%" y1="-21.87%" x2="88.33%" y2="121.88%" gradientUnits="userSpaceOnUse"
                            id="SvgjsLinearGradient1047">
                            <stop stopColor="#0e2a47" offset="0"></stop>
                            <stop stopColor="#00459e" offset="1"></stop>
                        </linearGradient>
                    </defs>
                </svg>
            </div>
        </>
    );
};

export default CommonSection;